export const MAP_BOX_KEY =
  'pk.eyJ1Ijoiam9hb3BwZWRyb3NhIiwiYSI6ImNqb3BrbXZkODFlbnczcnV1aGpmNXoxZ3QifQ.J9a-A5T4b7x5l-GK2Ct03w'
export const MAPCENTER = [-8.655528, 40.642343]
export const MAPZOOM = [14]
export const BASE_API_URL = 'https://mng-parking-aveiro.ubiwhere.com/api'
export const BASE_MEDIA_URL = 'https://mng-parking-aveiro.ubiwhere.com'

export const WS_JOIN_GROUP = 'parking_platform'
export const WS_BASE_URL = 'wss://mng-parking-aveiro.ubiwhere.com/ws/'

export const SIDEBAR_LOGO = 'citibrain'

export const NOMINATIM_BASE_URL = 'https://nominatim.openstreetmap.org'

export const SUPERSETURL =
  '&token_type=JWT&standalone=true&redirect=https://superset-parking-aveiro.ubiwhere.com/superset/dashboard/7/'
export const BASE_SUPERSETURL = 'https://superset-parking-aveiro.ubiwhere.com/'

export const CRUD_PERMISSIONS_GROUP = 'FULL ADMINISTRATOR'

export const UNAVAILABLE_FEATURES = ['footer']

export const MOBILE_DEEPLINK = 'parking-aveiro'

export const NOMINATIM_OPTIONS = {
  format: 'jsonv2',
  countrycodes: 'pt',
  viewbox: '-8.63834,41.59105,-7.98603,41.33996',
  bounded: 1
}

export const AVAILABLE_SPOT_TYPES = [
  // 'all',
  // 'normal',
  'cp',
  'handicapped',
  'motorhome'
  // 'load_unload'
]
